<template>
  <app-collapse
    accordion
    :type="'border'"
  >
    <div v-if="itinerary">
      <b-alert
        v-if="!(itineraryToRender && itineraryToRender.length) && (getLoading || processValue || processValue === 0 || !statusJobDone)"
        show
        variant="info"
        class="mb-0 p-1"
      >
        {{ $t('flight.searching') }}...
      </b-alert>
      <b-alert
        v-else-if="!(itineraryToRender && itineraryToRender.length) && !(getLoading || processValue || processValue === 0)"
        show
        variant="warning"
        class="mb-0 p-1"
      >
        {{ $t('flight.notBookingFound') }}
      </b-alert>
      <div v-else>
        <div
          v-for="(item, index) in itineraryToRender"
          :key="item.id"
        >
          <div
            v-show="isRenderArray[index] === undefined ? true : isRenderArray[index]"
            style="margin-bottom: 1px"
          >
            <app-collapse-flight
              :item="item"
              :index="index"
              :min-price="pricesFilter[0]"
              :selected-trip.sync="selectedTripTemp"
              :index-itinerary="indexItinerary"
              @clear="$emit('clear')"
            >
              <BookingDetailTable
                :flight-search="flightSearch"
                :trip="item"
                :index="index"
                :index-itinerary="indexItinerary"
                :selected-trip.sync="selectedTripTemp"
                @clear="$emit('clear')"
              />
            </app-collapse-flight>
          </div>
        </div>
      </div>
    </div>
  </app-collapse>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import {
  computed, watch, ref, onBeforeUnmount,
} from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { BUSINESS_FILTER_REGEX, SKYBOSS_FILTER_REGEX, BUSINESS_SKYBOSS_FILTER_REGEX } from '@/constants/flight'
import store from '@/store'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BAlert,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseFlight: () => import('./AppCollapseFlight.vue'),
    BookingDetailTable: () => import('./BookingDetailTable.vue'),
  },
  props: {
    itinerary: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: [Array, Object, undefined],
      default: () => {},
    },
    indexItinerary: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      FLIGHT_APP_STORE_MODULE_NAME, clearIntervalIDs, getLoading, processValue, isDomestic, statusJobDone,
      // airlineRoundtrip, tabIndex,
    } = useBookingHandle()
    const flightSearch = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`])
    const selectedTripTemp = ref()

    const itineraryAfterFilter = ref(null)
    watch(() => selectedTripTemp.value, () => {
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDraftSelectedTrip`, { data: cloneDeep(selectedTripTemp.value), index: props.indexItinerary })
      emit('update:selectedTrip', cloneDeep(selectedTripTemp.value))
      if (selectedTripTemp.value) {
        store.dispatch('app-flight-v2/setCombinationSelectedTrip', null)
      }
    })

    watch(() => props.selectedTrip, () => {
      if (!props.selectedTrip) { selectedTripTemp.value = null }
    })

    const combinationKeys = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getCombinationKeys`])

    watch([
      isDomestic,
      // airlineRoundtrip
    ], () => {
      if (!isDomestic.value) {
        itineraryAfterFilter.value = null
        // (airlineRoundtrip.value && (tabIndex.value !== props.indexItinerary))
        //   ? props.itinerary.filter(item => item.source === airlineRoundtrip.value)
        //   : null
        if (combinationKeys.value && itineraryAfterFilter.value) {
          const arrayResult = []
          itineraryAfterFilter.value.forEach(trip => {
            const filteredTrip = trip.fareOptions.filter(fareOpt => {
              const output = fareOpt.combinationKeys.filter(item => combinationKeys.value.indexOf(item) !== -1)
              return output.length > 0
            })
            if (filteredTrip.length) {
              trip.fareOptions = filteredTrip
              arrayResult.push(trip)
            }
          })
          itineraryAfterFilter.value = arrayResult
        }
      }
    })

    const itineraryToRender = computed(() => itineraryAfterFilter.value ? itineraryAfterFilter.value : props.itinerary)

    watch(itineraryToRender, () => {
      emit('update:itineraryToRender', itineraryToRender.value)
    }, { deep: true, immediate: true })

    onBeforeUnmount(() => {
      clearIntervalIDs()
    })

    const sourcesFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterBySources`])
    const airlinesFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByAirlines`])
    const stopPointsFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByStopPoints`])
    const transitPointsFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByTransitPoints`])
    const pricesFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByPrices`])
    const pricesFilterInit = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterPrices`])
    const durationsFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByDurations`])
    const departureTimeFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByDepartureTime`])
    const airCraftsFilter = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByAirCrafts`])
    const fareTypeBusiness = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByFareTypeBusiness`])
    const fareTypeSkyboss = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getFilterByFareTypeSkyboss`])

    const isRenderArray = ref([])
    watch([itineraryToRender, airlinesFilter, sourcesFilter, stopPointsFilter, transitPointsFilter, durationsFilter, departureTimeFilter, airCraftsFilter, fareTypeBusiness, fareTypeSkyboss, pricesFilter], () => {
      try {
        isRenderArray.value = itineraryToRender.value.map(trip => {
          const tripSource = trip.source
          if (sourcesFilter.value.length === 0 || sourcesFilter.value.includes(tripSource)) {
            //
          } else {
            return false
          }
          const tripAirline = trip.segments[0].operating === 'BL' ? 'BL' : trip.airline
          if (airlinesFilter.value.length === 0 || airlinesFilter.value.includes(tripAirline)) {
            // TODO: Lần filter đầu tiên nếu chọn VN sẽ ra cả BL
          } else {
            return false
          }
          if (stopPointsFilter.value.length && !stopPointsFilter.value.includes(trip.legs - 1)) {
            return false
          }
          const stopPoints = trip.stopPoint.split(' | ')
          const stopPointsInclude = transitPointsFilter.value.some(point => stopPoints.includes(point))
          if (transitPointsFilter.value.length === 0 || stopPointsInclude) {
            //
          } else {
            return false
          }
          if (durationsFilter.value.length) {
            if (trip.duration < durationsFilter.value[0] || trip.duration > durationsFilter.value[1]) {
              return false
            }
          }
          const parsedTime = moment.utc(trip.departure.at).utcOffset(trip.departure.timezone)
          if (departureTimeFilter.value.length) {
            return parsedTime.hours() * 60 + parsedTime.minutes() >= departureTimeFilter.value[0]
              && parsedTime.hours() * 60 + parsedTime.minutes() <= departureTimeFilter.value[1]
          }
          if (airCraftsFilter.value.length) {
            const airCraftsFilteredArr = airCraftsFilter.value.filter(val => trip.segments.map(s => s.airCraft).includes(val))
            if (airCraftsFilteredArr.length === 0) {
              return false
            }
          }

          let skipSkyboss = false
          if (fareTypeBusiness.value) {
            if (!trip.fareOptions.length) {
              return false
            }
            const filterRegex = fareTypeSkyboss.value ? BUSINESS_SKYBOSS_FILTER_REGEX : BUSINESS_FILTER_REGEX
            skipSkyboss = true
            const res = trip.fareOptions.some(fareItem => (['1S', 'VN1A'].includes(trip.source) && fareItem.groupClass.startsWith('B')) || (['QH', 'VJ', '1G'].includes(trip.source) && filterRegex.test(fareItem.fareType)))
            if (!res) return false
          }

          if (fareTypeSkyboss.value && !skipSkyboss) {
            if (!['VJ'].includes(trip.source)) return false
            if (!trip.fareOptions.length) {
              return false
            }
            const res = trip.fareOptions.some(fareItem => SKYBOSS_FILTER_REGEX.test(fareItem.fareType))
            if (!res) return false
          }

          if (pricesFilter.value.length) {
            if (!trip.fareOptions.length
              && (pricesFilter.value[0] === pricesFilterInit.value[0])
              && (pricesFilter.value[1] === pricesFilterInit.value[1])) {
              return true
            }
            const res = trip.fareOptions.every(fareItem => fareItem.afterMultiple.totalAdultModified < pricesFilter.value[0]
              || fareItem.afterMultiple.totalAdultModified > pricesFilter.value[1])
            if (res) {
              return false
            }
          }

          return true
        })

        emit('update:isRenderArray', isRenderArray.value)
      } catch (error) {
        console.log(error)
      } finally {
        store.dispatch('app-flight-v2/setLoading', false)
      }
    }, { deep: true, immediate: true })

    return {
      flightSearch,
      selectedTripTemp,
      getLoading,
      processValue,
      itineraryAfterFilter,
      itineraryToRender,
      sourcesFilter,
      airlinesFilter,
      isRenderArray,
      pricesFilter,
      statusJobDone,
    }
  },
}
</script>
